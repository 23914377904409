<template>
  <router-view />
</template>

<script>
import store from '../../store'
import firebase, { signin } from '../../firebase'

export default {
  async mounted () {
    await signin()

    console.log('mount')

    await this.loadParks()

    this.storeLive('visitors', store.visitors)
    this.storeLive('attractions', store.attractions)
    this.storeLive('queues', store.queues, true)
    this.storeLive('queues_history', store.queues_history, true)
  },
  methods: {
    async loadParks() {
      const snapshot = await firebase.db.collection('parks').get()

      store.parks = snapshot.docs.map(doc => doc.data())
    },
    findIndexById(needle, stack) {
      return stack.findIndex((item) => {
        return item.id === needle.id
      })
    },
    storeLive(collection, storeObject, today=false) {
      storeObject.splice(0, storeObject.length)

      let query = firebase.db.collection(collection)

      if (today) {
        query = query.where('available_at', '>=', this.startDay())
          .where('available_at', '<=', this.endDay())
      }

      query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const data = change.doc.data()

          if (change.type === 'added') {
            storeObject.push(data)
          }

          const index = this.findIndexById(data, storeObject)

          if (change.type === 'removed') {
            storeObject.splice(index, 1)
          }

          if (change.type === 'modified') {
            storeObject.splice(index, 1, data)
          }
        })
      })
    },
    startDay () {
      const date = new Date()
      date.setHours(0, 0, 0)
      return date.getTime() / 1000
    },
    endDay () {
      const date = new Date()
      date.setHours(23, 59, 59)
      return date.getTime() / 1000
    }
  }
}
</script>
